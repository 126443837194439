import { createMuiTheme } from '@material-ui/core/styles'

// NOTE check this https://material-ui.com/customization/default-theme/

const defaultTheme = createMuiTheme()
const { breakpoints } = defaultTheme

const modernFont = '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
const specialFont = `Poppins,${modernFont}`

const theme = createMuiTheme({
  mixins: {
    contactBanner: {
      minHeight: 50,
    },
    toolbar: {
      minHeight: 80,
    },
  },
  palette: {
    primary: { main: '#3841e0' },
    secondary: {
      main: 'rgb(37, 94, 193)',
      veryLight: '#cad8fa',
      lightTransparent: 'rgba(37, 94, 193, 0.3)',
      veryLightTransparent: 'rgba(37, 94, 193, 0.03)',
    },
    tertiary: { main: '#00b1cb' },
    quaternary: {
      main: '#ffb54e',
      lightTransparent: 'rgba(255, 181, 78, 0.25)',
      veryLightTransparent: 'rgba(255, 181, 78, 0.15)',
    },
    gradient: 'linear-gradient(45deg, #3841e0, #4d83dd)',
  },
  typography: {
    fontFamily: specialFont,
    modernFont,
    specialFont,
    link: {
      textDecoration: 'none',
      fontFamily: specialFont,
      fontWeight: 300,
      lineHeight: 1.5,
    },
    ctaLink: {
      fontSize: 17,
      fontWeight: '400',
      color: '#3841e0',
      fontStyle: 'italic',
      '&:visited': {
        color: 'rgb(37, 94, 193)',
      },
    },
  },
  centralise: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  right: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  circle: (side) => ({
    width: side,
    height: side,
    borderRadius: side / 2,
  }),
  maxMobileWidth: 400,
  overrides: {
    MuiTypography: {
      h4: {
        marginTop: 16,
        marginBottom: 16,
        [breakpoints.down('sm')]: {
          fontSize: '1.75rem',
        },
      },
      h6: {
        [breakpoints.down('sm')]: {
          fontSize: 16,
        },
      },
      subtitle1: {
        color: '#555',
        marginTop: 8,
        marginBottom: 8,
        fontSize: 18,
        [breakpoints.down('sm')]: {
          fontSize: 16,
        },
      },
      subtitle2: {
        color: '#555',
        fontWeight: 300,
        marginTop: 8,
        marginBottom: 8,
        fontSize: 20,
        [breakpoints.down('sm')]: {
          fontSize: 16,
        },
        fontFamily: specialFont,
      },
    },
    MuiButton: {
      root: {
        margin: 10,
        textTransform: 'none',
      },
      contained: {
        backgroundColor: '#c00',
        '&:hover': {
          backgroundColor: '#00c',
        },
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 16,
      },
      elevation1: {
        boxShadow: '0px 1px 12px 0px rgba(0,0,0,0.2)',
      },
    },
    MuiExpansionPanel: {
      rounded: {
        boxShadow: '1px 0px 40px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        borderRadius: 12,
        '&:first-child': {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
        '&:last-child': {
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
        },
      },
    },
  },
})

export default { ...theme, isMobile: theme.breakpoints.down('sm') }
