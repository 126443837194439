/* eslint-disable */
global.__DEV__ = process.env.NODE_ENV !== 'production'

const LOG_LEVEL = 'log' // 'log' (default), 'info' (shows everything) or 'warn' (only shows warnings)
const REMOTE_DEBUGGER = (typeof atob !== 'undefined') // true if remote debugger is available so the logger knows what to do
const WARN_ILLEGAL_STATE_MUTATIONS = false // can have a performance impact, enable only when necessary - automatically disabled in production

const CONTACT_EMAIL = 'hello@victorapps.com'
const SUPPORT_EMAIL = 'support@victorapps.com'
const CONTACT_PHONE = '+44 (0) 333 050 9420'

export default {
  LOG_LEVEL,
  REMOTE_DEBUGGER,
  WARN_ILLEGAL_STATE_MUTATIONS,
  CONTACT_EMAIL,
  SUPPORT_EMAIL,
  CONTACT_PHONE,
}