
import wrapWithProvider from './rootWrapperProvider'
import ReactGA from 'react-ga'

export const wrapRootElement = wrapWithProvider

export const onClientEntry = () => {
  console.log('Gatsby launch')
  ReactGA.initialize('UA-139196834-2') // VictorApps
  ReactGA.pageview(window.location.pathname + window.location.search)
}
